
import {WOW} from '../../../node_modules/wowjs/dist/wow';
// import './rem.js'

new WOW().init();
function  init() {
  if ( window.innerWidth>750) {
      var whdef = 100 / 1920;
      var wH = window.innerHeight;// 当前窗口的高度
      var wW = window.innerWidth;// 当前窗口的宽度
      var rem = wW * whdef;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
      $('html').css('font-size', rem + "px");
  }else {
      let designSize = 750; // 设计图尺寸
      let html = document.documentElement;
      let wW = html.clientWidth;// 窗口宽度
      let rem = wW * 100 / designSize; 
      document.documentElement.style.fontSize = rem + 'px';
  }  
}
$(window).resize(function (){// 绑定到窗口缩放的事件
  init();
});
//页面刚刷新时调用
init()
//滚动定位
$(".dl dl dt a").click(function() {
  let id = $(this).attr("href").split("#").pop()
  var top = $('#' + id).offset().top;
  $('html,body').animate({
      scrollTop: top - 50
  }, 500);
})


