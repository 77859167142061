module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="nei-banner">\r\n    <img src="' +
((__t = ( require('./images/telent-banner.png') )) == null ? '' : __t) +
'" />\r\n</div>';

}
return __p
}