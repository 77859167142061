import './index.scss'


$(".hamburger").click(function(){
    $(this).toggleClass("active");
    $(".mob-nav").toggleClass("active");
})
$("header .nav").hover(function(){
    
},function(){
   $(".header-bg").stop().hide()
})
$(".nav li").hover(function(){
    $(".header-bg").stop().show()
    $(this).find(".navchild").stop().show()
},function(){
    $(this).find(".navchild").stop().hide()
})
$(".search").click(function(){
    $("header .head").hide();
    $(".search-box").css("display","flex");
})
$(".search-out").click(function(){
    $("header .head").show();
    $(".search-box").hide();
})
$(".nav li:first").hover(function(){
    $(".header-bg").stop().hide()
})
// $(".nav li:last").hover(function(){
//     $(".header-bg").stop().hide()
// })
$(".mob-nav .nLi").click(function() {
    $(this).find(".sub").slideToggle();
    $(this).toggleClass("active");
})
$("header .head .nav ul li .navchild .content .dl dl dt a").click(function() {
    $(".header-bg").stop().hide()
    $("header .head .nav ul li .navchild").hide()
})


//滚动定位
$(document).ready(function() {
    if (location.hash) {
        setTimeout(function() {
            var targetOffset = $(location.hash).offset().top;
            $('html, body').animate({
                scrollTop: targetOffset - 98
            }, 500);
        }, 10);
    }
});
  